import { Stack, Typography, useTheme } from '@mui/material';

import { Link, useLocation } from '@remix-run/react';
import { Search as IconSearch } from 'react-feather';

import Button from '~/components/atoms/Button';
import Heading from '~/components/atoms/Heading';
import Paragraph from '~/components/atoms/Paragraph';
import FrontContainer from '~/components/molecules/FrontContainer';

import { imageUrls } from '~/assets';

interface IEmptyBookshelf {
    userIsSubscriber?: boolean;
    isOrgUser?: boolean;
}

const EmptyBookshelf = ({ userIsSubscriber, isOrgUser }: IEmptyBookshelf) => {
    const theme = useTheme();
    const location = useLocation();

    return (
        <FrontContainer maxWidth="md">
            <Stack
                sx={{
                    mb: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 1,
                }}
            >
                <Heading variant="h1" color="text.secondary" sx={{ mb: 6, textAlign: 'center' }}>
                    Er du klar?
                </Heading>
                <Paragraph variant="p1" sx={{ mb: 12, textAlign: 'center' }}>
                    Foreløpig er bokhylla di tom, men du har gode muligheter til <br />
                    raskt å bygge opp et helt bibliotek!
                </Paragraph>
                <Stack
                    sx={{
                        mb: 12,
                    }}
                >
                    <Stack
                        sx={{
                            mb: 6,
                            pt: { xs: 8, sm: 8 },
                            pr: { xs: 8, md: '286px' },
                            pb: { xs: 8, md: 8 },
                            pl: { xs: 8, md: 10 },
                            alignItems: { xs: 'center', md: 'flex-start' },
                            width: 1,
                            backgroundColor: theme.palette.primary.main,
                            backgroundImage: { xs: 'none', md: `url(${imageUrls.extractedSmartStudent})` },
                            backgroundPosition: 'bottom right',
                            backgroundSize: '226px auto',
                            backgroundRepeat: 'no-repeat',
                            borderRadius: theme.radius.allXLarge,
                        }}
                    >
                        <Typography
                            variant="p1"
                            sx={{
                                display: 'block',
                                mb: 8,
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            {userIsSubscriber
                                ? 'Du har tilgang til Allvit+! Det er bare å søke og legge til bøker i bokhylla di.'
                                : 'Med abonnementet Allvit+ får du tilgang til omtrent 2000 bøker – rett i bokhylla di.'}
                        </Typography>
                        {userIsSubscriber ? (
                            <Button
                                component={Link}
                                to="/sok/boker?allvitPluss=1&gratis=1"
                                state={{ from: location.pathname }}
                                variant="primary"
                                size="large"
                                overrideColor={theme.palette.text.primary}
                                startIcon={<IconSearch color={theme.palette.common.white} />}
                                sx={{ width: { xs: 1, sm: 'auto' } }}
                            >
                                Søk i Allvit+
                            </Button>
                        ) : (
                            <Stack
                                sx={{
                                    width: 1,
                                    justifyContent: { xs: 'center', md: 'flex-start' },
                                    flexDirection: { xs: 'colum', sm: 'row' },
                                }}
                            >
                                <Button
                                    component={Link}
                                    to="/start-allvit-pluss"
                                    overrideColor={theme.palette.text.primary}
                                    variant="primary"
                                    size="large"
                                    sx={{
                                        width: { xs: 1, sm: 'auto' },
                                        mb: { xs: 4, sm: 0 },
                                        mr: { xs: 0, sm: 4 },
                                        justifyContent: { xs: 'center', sm: 'flex-start' },
                                    }}
                                >
                                    Start Allvit+ nå!
                                </Button>
                                <Button
                                    component={Link}
                                    to="/allvit-pluss"
                                    overrideColor={theme.palette.text.primary}
                                    variant="secondary"
                                    size="large"
                                    sx={{ width: { xs: 1, sm: 'auto' }, mb: { xs: 4, sm: 0 } }}
                                >
                                    Les mer om Allvit+
                                </Button>
                            </Stack>
                        )}
                    </Stack>

                    <Stack
                        sx={{
                            mb: 6,
                            px: 10,
                            py: 8,
                            alignItems: { xs: 'center', md: 'flex-start' },
                            width: 1,
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: theme.radius.allXLarge,
                        }}
                    >
                        <Typography
                            variant="p1"
                            sx={{
                                display: 'block',
                                mb: 8,
                                textAlign: { xs: 'center', md: 'left' },
                                color: theme.palette.common.white,
                            }}
                        >
                            Mange av bøkene i Allvit er gratis. Søk, og legg dem til i bokhylla di.
                        </Typography>
                        <Button
                            component={Link}
                            to="/sok/boker?gratis=1"
                            state={{ from: location.pathname }}
                            variant="secondary"
                            size="large"
                            overrideColor={theme.palette.common.white}
                            startIcon={<IconSearch color={theme.palette.common.white} />}
                            sx={{ width: { xs: 1, sm: 'auto' } }}
                        >
                            Søk i gratis bøker
                        </Button>
                    </Stack>
                    {!isOrgUser && (
                        <Stack
                            sx={{
                                mb: 6,
                                px: 10,
                                py: 8,
                                alignItems: { xs: 'center', md: 'flex-start' },
                                width: 1,
                                backgroundColor: theme.palette.background.dark,
                                borderRadius: theme.radius.allXLarge,
                            }}
                        >
                            <Typography
                                variant="p1"
                                sx={{
                                    display: 'block',
                                    mb: 8,
                                    textAlign: { xs: 'center', md: 'left' },
                                }}
                            >
                                Har du allerede bøker lagret i Bokskya, kan du enkelt legge til disse.
                            </Typography>
                            <Button
                                component={Link}
                                to="/profil/bokskya"
                                variant="secondary"
                                size="large"
                                sx={{ width: { xs: 1, sm: 'auto' } }}
                            >
                                Koble til Bokskya
                            </Button>
                        </Stack>
                    )}
                </Stack>
                <Button
                    component={Link}
                    to="/sok/boker"
                    state={{ from: location.pathname }}
                    variant="primary"
                    size="large"
                    startIcon={<IconSearch color={theme.palette.common.white} />}
                    sx={{
                        width: '100%',
                        [theme.breakpoints.up('sm')]: {
                            width: 'auto',
                        },
                    }}
                >
                    Søk i alle bøker
                </Button>
            </Stack>
        </FrontContainer>
    );
};

export default EmptyBookshelf;
